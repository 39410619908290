
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, addMoneyMask, formatDate } from "@/libs/utils";
import { CashierTransaction } from "@/store/cashier/transaction/types";

const VCashierTransactionList = defineComponent({
  name: "VCashierTransactionList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de transações | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const transactions = computed(() => store.state.cashier.transaction.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(transaction: CashierTransaction) {
      const items: DropdownItem[] = [
        {
          label: "Guias eletrônicas",
          icon: "fas fa-receipt",
          to: { name: "cashier-transaction-guide", params: { _id: transaction.id_guias?.join("-") } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Financeiro",
        icon: "fas fa-wallet mr-1",
        to: { name: "cashier-historic-list", query: route.query },
      },
      {
        label: "Transações",
        icon: "fas fa-coins mr-1",
        to: { name: "cashier-transaction-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "cashier.transaction",
      setPage: "setCashierTransactionPaginationPage",
      updateList: getCashierTransactions,
    });

    async function getCashierTransactions() {
      loading.list = true;
      await store.dispatch("getCashierTransactions");
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "cashier-transaction-filter", query: route.query });
    else getCashierTransactions();

    return {
      ...{ transactions, pagination, loading, breadcrumb, hasFilters },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getCashierTransactions },
      ...{ addMoneyMask, formatDate },
    };
  },
});

export default VCashierTransactionList;
